<template>
  <page-container title="保养任务" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="任务单号" prop="maintenancordernum">
              <a-input v-model.trim="queryParams.maintenancordernum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯编号" prop="liftnum">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="处理人账号" prop="processperson">
              <a-input v-model.trim="queryParams.processperson" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="维保类型" prop="maintenancescheduletype">
              <a-select v-model="queryParams.maintenancescheduletype">
                <a-select-option v-for="(item, index) in maintenanceTypes" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="节点状态" prop="processstep">
              <a-select v-model="queryParams.processstep">
                <a-select-option v-for="(item, index) in orderstepOptions" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepid">-->
<!--              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :allow-clear="false"></a-cascader>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保单位" prop="maintenancedepid">-->
<!--              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维护单位" :allow-clear="false"></a-cascader>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保组" prop="maintenancegroupid">-->
<!--              <a-select v-model="queryParams.maintenancegroupid">-->
<!--                <a-select-option v-for="(item, index) in maintenancegroupidOptions" :key="index" :value="item.maintenancegroupid">{{item.maintenancegroupname}}</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname">
              <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保单位" prop="maintenancedepname">
              <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维保组" prop="maintenancegroupname">
              <a-input v-model="queryParams.maintenancegroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安装地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">保养任务</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)" style="margin-top: 5px"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenancorderid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="maintenancordernum" slot-scope="value, record">
            <span v-if="record.overdue" class="table-dot" style="background-color: #ff4d4f;"></span>
            <a-tooltip :title="value">
              <span>{{value}}</span>
            </a-tooltip>
          </span>
          <span slot="maintenancescheduletype" slot-scope="value">
            {{maintenanceTypesMap[value] || value}}
          </span>
          <span slot="needfinishtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="processstep" slot-scope="value">
            <a-tag :color="orderstepscolorMap[value]||''">{{orderstepsMap[value]||''}}</a-tag>
          </span>
          <span slot="order_exception" slot-scope="value">
            <a-tag :color="orderExceptionColors[value]||''">{{orderException[value]||''}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="detail(value, record, index)">详情</a-button>
            <!-- <a-button type="link" size="small" @click="liftdetail(value, record, index)">设备详情</a-button>
            <a-button type="link" size="small" @click="cancelConfirm(value, record, index)">撤销</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button> -->
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'liftdetail-'+record.maintenancorderid">设备详情</a-menu-item>
                <a-menu-item :key="'export-'+record.maintenancorderid">导出文档</a-menu-item>
<!--                <a-menu-item :key="'cancel-'+record.maintenancorderid">撤销</a-menu-item>-->
                <a-menu-item v-if="userInfo.usertype==0" :key="'delete-'+record.maintenancorderid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
          <span slot="abnormal" slot-scope="value, record">
            <a-button type="link" size="small" v-if="record.facerecognize==1" @click="maintenanceAbnormal(record,value)">异常</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="maintenanceAbnormalVisible">
      <template slot="footer">
        <a-button @click="maintenanceAbnormalVisible=false">关闭</a-button>
      </template>
      <h3 style="font-size: 22px">维保工单异常情况</h3>
      <a-form-model ref="advancedForm" :model="maintenanceAbnormalData" layout="inline" class="query-form">
        <a-form-model-item label="身份认证异常" prop="facerecognize">
          <a-input v-model.trim="maintenanceAbnormalData.facerecognize==1?'认证异常':''" placeholder="" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="异常时处理人" prop="manageperson">
          <a-input v-model.trim="maintenanceAbnormalData.manageperson" placeholder="" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="异常发生时间" prop="abnormaltime">
          <a-input v-model.trim="moment(maintenanceAbnormalData.abnormaltime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" placeholder="" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保单位名称" prop="maintenancedep">
          <a-input v-model.trim="maintenanceAbnormalData.maintenancedep" placeholder="" :disabled="true"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <detail-modal :visible.sync="detailModalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></detail-modal>
    <liftdetail-modal :visible.sync="liftdetailModalVisible" :detail-data="modalDetailData"></liftdetail-modal>
  </page-container>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import orderstepOptions from '@/json/orderstepOptions'
import orderstepsMap, {orderException, orderExceptionColors} from '@/json/ordersteps'
import orderstepscolorMap from '@/json/orderstepscolor'
import { getDictByDicType, getMentenacegroupListByCondition } from 'A/xtpz.js'
import {
  findMaintenanceorderListByCondition,
  deleteMaintenanceorder,
  cancelMaintenanceorder,
  getMaintenanceorderInfoByorderId, findWorkflowByorderId
} from 'A/wbgl.js'
import DetailModal from './DetailModal'
import LiftdetailModal from './LiftdetailModal'
import {exporMaintenanceWordStatistic, exportkeepMaintenanceWordStatistic} from "A/bigdata";
import orderstatusMap from "@/json/orderstatusMap";
import resultcodeMap from "@/json/resultcodeMap";
import {findMaintenanceitemrecordListByOrderId} from "A/wbgl";
import maintenanceitemStatusMap from "@/json/maintenanceitemStatusMap";
export default {
  name: 'maintenanceTask',
  mixins: [deptselect, pagination],
  components: {
    DetailModal,
    LiftdetailModal,
  },
  data() {
    return {
      moment,
      maintenanceTypes: [],
      orderstepOptions,
      orderstepsMap,
      orderstepscolorMap,
      orderException,
      orderExceptionColors,
      maintenancegroupidOptions: [],
      breadcrumb: [
        {
          name: '维保管理',
          path: ''
        },
        {
          name: '保养管理',
          path: ''
        },
        {
          name: '保养任务',
          path: ''
        },
      ],
      showAdvanced: false,
      queryParams: {
        maintenancordertype: '2',
        maintenancordernum: '',
        liftnum: '',
        liftrescue: '',
        
        processperson: '',
        maintenancescheduletype: '',
        processstep: '',
        userdepid: '',
        maintenancedepid: '',
        maintenancegroupid: '',
        adress: '',
        userdepname:'',
        maintenancedepname:'',
        maintenancegroupname:'',
        order_exception:'',
      },
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      tableColumns: [
        {
          title: '任务单号',
          dataIndex: 'maintenancordernum',
          key: 'maintenancordernum',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenancordernum' }
        },
        {
          title: '电梯编码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '电梯救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenancedepname',
          key: 'maintenancedepname',
          ellipsis: true,
        },
        {
          title: '维保类型',
          dataIndex: 'maintenancescheduletype',
          key: 'maintenancescheduletype',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenancescheduletype' }
        },
        {
          title: '应完成日期',
          dataIndex: 'needfinishtime',
          key: 'needfinishtime',
          ellipsis: true,
          scopedSlots: { customRender: 'needfinishtime' }
        },
        {
          title: '作业时间状态',
          dataIndex: 'order_exception',
          key: 'order_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'order_exception' },
        },
        {
          title: '节点状态',
          dataIndex: 'processstep',
          key: 'processstep',
          ellipsis: true,
          scopedSlots: { customRender: 'processstep' }
        },
        {
          title: '操作',
          key: 'operation',
          align:'center',
          width:80,
          scopedSlots: { customRender: 'operation' }
        },
        {
          title: '异常',
          key: 'abnormal',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'abnormal' }
        },
      ],
      tableData: [],
      tableLoading: false,
      detailModalVisible: false,
      modalDetailData: null,
      liftdetailModalVisible: false,
      counts:'',
      maintenanceAbnormalVisible:false,
      maintenanceAbnormalData:{
        manageperson:'',
        abnormaltime:'',
        facerecognize: '',
        maintenancedep:'',
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    maintenanceTypesMap() {
      let result = {};
      this.maintenanceTypes.forEach(item => {
        result[item.dickey] = item.dicvalue;
      })
      return result;
    },
  },
  watch: {
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
      }else {
        this.queryParams.maintenancedepid = '';
      }
    },
    maintenanceTypesMap() {
      let result = {};
      this.maintenanceTypes.forEach(item => {
        result[item.dickey] = item.dicvalue;
      })
      return result;
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.initMaintenanceTypes();
      this.initMaintenancegroupidOptions();
      this.getTableData();
    },
    initMaintenanceTypes() {
      let params = {
        dictype: '15',
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenanceTypes = res.item;
        }
      })
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.queryParams.maintenancedepid,
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        isnotauto:"true",
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findMaintenanceorderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(item => {
            if(item.processstep != '81') {
              if(item.needfinishtime) {
                if(moment() > moment(item.needfinishtime, 'YYYYMMDD')) {
                  item.overdue = true;
                }
              }
            }
            return item;
          });
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    detail(value, record) {
      this.modalDetailData = record;
      this.detailModalVisible = true;
    },
    liftdetail(value, record) {
      // this.modalDetailData = record;
      // this.liftdetailModalVisible = true;
      this.$router.push({
        name: 'elevatorArchivesDetail',
        params: {
          liftid: record.liftid,
          prePages: [
            {
              name: '保养管理',
              path: ''
            },
            {
              name: '保养任务',
              path: 'maintenanceTask'
            }
          ]
        }
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.maintenancorderid);
        },
        onCancel() {},
      });
    },
    delete(maintenancorderid) {
      this.showLoading();
      if(maintenancorderid) {
        let params = {
          maintenancorderid
        };
        deleteMaintenanceorder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    cancelConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.cancelOrder(record.maintenancorderid);
        },
        onCancel() {},
      });
    },
    cancelOrder(maintenancorderid) {
      this.showLoading();
      if(maintenancorderid) {
        let params = {
          maintenancorderid
        };
        cancelMaintenanceorder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    exportWord(record){
      let param = {
        maintenancorderid: record.maintenancorderid
      }
      getMaintenanceorderInfoByorderId(param).then(res => {
        if (res && res.returncode == '0') {
          record = res.item
          findMaintenanceitemrecordListByOrderId({maintenanceorderid:record.maintenancorderid.toString()}).then(res => {
            if (res && res.returncode == '0') {
            findWorkflowByorderId({maintenancorderid:record.maintenancorderid.toString()}).then(ress => {
              if(ress && ress.returncode == '0') {
                let length=ress.item.length
                let params = {
                  maintenancedepname: record.maintenancedepname,
                  maintenancescheduletype: this.maintenanceTypesMap[record.maintenancescheduletype],
                  creattime: record.creattime ? moment(record.creattime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : "",
                  maintenancorderid: record.maintenancorderid,
                  ordersList: [
                    {col1: record.maintenancordernum},
                    {col1: record.liftnum},
                    {col1: record.liftrescue},
                    {col1: record.adress},
                    {col1: record.brandname},
                    {col1: record.devicemodelname},
                    {col1: record.creattime ? moment(record.creattime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""},
                    {col1: record.needfinishtime ? moment(record.needfinishtime, "YYYYMMDD").format("YYYY-MM-DD") : ""},
                    {col1: this.maintenanceTypesMap[record.maintenancescheduletype]},
                    {col1: record.maintenancedepname},
                    {col1: orderstatusMap[record.orderstatus]},
                    {col1: orderstepsMap[record.processstep]},
                  ],
                  orderPullList: [
                    {col1: record.dispatchperson},
                    {col1: record.dispatchtime ? moment(record.dispatchtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""},
                    {col1: record.takeperson},
                    {col1: record.taketime ? moment(record.taketime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""},
                    {col1: record.registerperson},
                    {col1: record.registertime ? moment(record.registertime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""},
                    {col1: record.signinaddress},
                    {col1: record.checkstarttime ? moment(record.checkstarttime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""},
                    {col1: record.checkendtime ? moment(record.checkendtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""},
                    {col1: resultcodeMap[record.resultcode]},
                    {col1: record.resultdecs},
                    {col1: record.orderdecs},
                    {col1: record.resultdecs},
                  ],
                  evaluateList: [
                    {col1: record.groupmanauditgrade + "星"},
                    {col1: record.userauditgrade + "星"}
                  ],
                  examineList: [
                    {col2: maintenanceitemStatusMap[res.item[0].maintenanitemstatus], col3: res.item[0].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[1].maintenanitemstatus], col3: res.item[1].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[2].maintenanitemstatus], col3: res.item[2].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[3].maintenanitemstatus], col3: res.item[3].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[4].maintenanitemstatus], col3: res.item[4].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[5].maintenanitemstatus], col3: res.item[5].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[6].maintenanitemstatus], col3: res.item[6].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[7].maintenanitemstatus], col3: res.item[7].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[8].maintenanitemstatus], col3: res.item[8].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[9].maintenanitemstatus], col3: res.item[9].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[10].maintenanitemstatus], col3: res.item[10].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[11].maintenanitemstatus], col3: res.item[11].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[12].maintenanitemstatus], col3: res.item[12].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[13].maintenanitemstatus], col3: res.item[13].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[14].maintenanitemstatus], col3: res.item[14].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[15].maintenanitemstatus], col3: res.item[15].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[16].maintenanitemstatus], col3: res.item[16].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[17].maintenanitemstatus], col3: res.item[17].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[18].maintenanitemstatus], col3: res.item[18].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[19].maintenanitemstatus], col3: res.item[19].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[20].maintenanitemstatus], col3: res.item[20].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[21].maintenanitemstatus], col3: res.item[21].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[22].maintenanitemstatus], col3: res.item[22].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[23].maintenanitemstatus], col3: res.item[23].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[24].maintenanitemstatus], col3: res.item[24].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[25].maintenanitemstatus], col3: res.item[25].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[26].maintenanitemstatus], col3: res.item[26].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[27].maintenanitemstatus], col3: res.item[27].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[28].maintenanitemstatus], col3: res.item[28].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[29].maintenanitemstatus], col3: res.item[29].resultdesc},
                    {col2: maintenanceitemStatusMap[res.item[30].maintenanitemstatus], col3: res.item[30].resultdesc},
                  ],
                  flowstepList:[
                    {col0:0<length?orderstepsMap[ress.item[0].status]:"",col1:0<length?ress.item[0].person:"",col2:0<length?(ress.item[0].finishtime?moment(ress.item[0].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:1<length?orderstepsMap[ress.item[1].status]:"",col1:1<length?ress.item[1].person:"",col2:1<length?(ress.item[1].finishtime?moment(ress.item[1].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:2<length?orderstepsMap[ress.item[2].status]:"",col1:2<length?ress.item[2].person:"",col2:2<length?(ress.item[2].finishtime?moment(ress.item[2].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:3<length?orderstepsMap[ress.item[3].status]:"",col1:3<length?ress.item[3].person:"",col2:3<length?(ress.item[3].finishtime?moment(ress.item[3].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:4<length?orderstepsMap[ress.item[4].status]:"",col1:4<length?ress.item[4].person:"",col2:4<length?(ress.item[4].finishtime?moment(ress.item[4].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:5<length?orderstepsMap[ress.item[5].status]:"",col1:5<length?ress.item[5].person:"",col2:5<length?(ress.item[5].finishtime?moment(ress.item[5].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:6<length?orderstepsMap[ress.item[6].status]:"",col1:6<length?ress.item[6].person:"",col2:6<length?(ress.item[6].finishtime?moment(ress.item[6].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:7<length?orderstepsMap[ress.item[7].status]:"",col1:7<length?ress.item[7].person:"",col2:7<length?(ress.item[7].finishtime?moment(ress.item[7].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:8<length?orderstepsMap[ress.item[8].status]:"",col1:8<length?ress.item[8].person:"",col2:8<length?(ress.item[8].finishtime?moment(ress.item[8].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                    {col0:9<length?orderstepsMap[ress.item[9].status]:"",col1:9<length?ress.item[9].person:"",col2:9<length?(ress.item[6].finishtime?moment(ress.item[9].finishtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss") : ""):""},
                  ],
                }
                this.showLoading();
                exportkeepMaintenanceWordStatistic(params).then(() => {
                  this.hideLoading();
                }).catch(() => {
                  this.hideLoading();
                })
              }
          })
        }
      })
    }
  })
},
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let maintenancorderid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenancorderid', maintenancorderid);
      if(type == 'liftdetail') {
        this.liftdetail('', record);
      }else if(type == 'cancel') {
        this.cancelConfirm('', record);
      }else if(type == 'delete') {
        this.deleteConfirm('', record);
      }else if(type == 'export') {
        this.exportWord(record);
      }
    },
    maintenanceAbnormal(record,value){
      console.log(record)
      this.maintenanceAbnormalData.facerecognize=record.facerecognize
      this.maintenanceAbnormalData.maintenancedep=record.maintenancedepname
      this.maintenanceAbnormalData.manageperson=record.manageperson
      this.maintenanceAbnormalData.abnormaltime=record.abnormaltime
      this.maintenanceAbnormalVisible=true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>